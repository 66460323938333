import React, { ComponentProps } from "react";

import { observer } from "gather-browser-common/dist/src/public/frontendMobxUtils";
import { Box, Flex, Text } from "gather-design-system";
import t, { Translated } from "src/i18n/t";

interface Props {
  loadingText?: Translated;
  loadingTextMinHeight?: ComponentProps<typeof Box>["minHeight"];
}

export const LoadingScreen = observer(function LoadingScreen({
  loadingText,
  loadingTextMinHeight
}: Props) {
  return (
    <Flex align="center" justify="center" direction="column" gap={20}>
      <img src="/images/grapes.png" width={100} />

      <Box minHeight={loadingTextMinHeight}>
        <Text color="accentSecondary" textAlign="center">
          {loadingText ?? t("33ce417")}
        </Text>
      </Box>
    </Flex>);

});