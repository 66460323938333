import { LoadingScreen } from "components/ui/LoadingScreen"
import React from "react"

import { Gothify, ThemeProvider } from "gather-design-system"
import { Translated } from "src/i18n/t"
import { LoadingFullScreenContainer } from "src/routes/LoadingFullScreenContainer"
import { uiConfigClass } from "src/uiConfig.css"

type Props = {
  loadingText?: Translated
}

export const RootRouteLoadingFallback = React.memo(function RootRouteLoadingFallback({
  loadingText,
}: Props) {
  return (
    <ThemeProvider theme="light" className={uiConfigClass}>
      <Gothify>
        <LoadingFullScreenContainer>
          <LoadingScreen loadingText={loadingText} loadingTextMinHeight={52} />
        </LoadingFullScreenContainer>
      </Gothify>
    </ThemeProvider>
  )
})
