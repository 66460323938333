/* eslint-disable @gathertown/no-literal-string-in-jsx */
import React, { PropsWithChildren } from "react"

import { observer } from "gather-browser-common/dist/src/public/frontendMobxUtils"
import { Flex, Gothify } from "gather-design-system"

export const LoadingFullScreenContainer = observer(function LoadingFullScreenContainer({
  children,
}: PropsWithChildren) {
  return (
    <Gothify>
      <Flex
        width="100vw"
        height="100vh"
        overflow="hidden"
        backgroundColor="accentQuaternary"
        align="center"
        justify="center"
      >
        {children}
      </Flex>
    </Gothify>
  )
})
